import React, { Component } from "react";
import "./About.css";

class About extends Component {
  render() {
    return (
      <div className="about">
        <div>
          <img style={{ width: "100%" }} src="/assets/night.jpg" alt="crux" />
        </div>

        <h2 style={{ textAlign: "center", marginTop: 20 }}>
          Crux - Chòm sao Nam thập tự
        </h2>
        <div>
          <p>
            <i>
              Blue midnight
              <br />
              The rain has just stopped
              <br />
              The stars begin coming out
              <br />
              —
              <br />
              Far away
              <br />
              The Southern Cross is shining
              <br />
              My journey begins
            </i>
          </p>
        </div>
        <hr />
        <p>
          Crux là tên của 1 chòm sao, nó còn có tên khác là Northern Cross, tên
          tiếng Việt của nó là Nam Thập Tự, ngôn ngữ dân gian là Chữ X phương
          Nam.
        </p>
        <p>
          Đó là một chòm sao đặc biệt vì hầu như chỉ xuất hiện vào bầu trời đêm
          ở bán cầu Nam.
        </p>
        <p>
          Ngày xưa, những người di cư về phía Nam sử dụng nó để xác định phương
          hướng. Nó như một cái la bàn đối với những người viễn chinh về phía
          Nam.
        </p>
        <p>
          Hắn cũng đi xa như vậy. Trong những ngày đầu đặt chân đến mảnh đất ở
          bán cầu Nam, hắn cũng hay ngắm bầu trời, và chòm Nam Thập Tự luôn là
          chòm được hắn để ý nhất.
        </p>
        <p>
          Dần dần, chòm sao đó luôn nhắc cho hắn biết là hắn đang ở mảnh đất
          mới, với một bầu trời mới.
        </p>
        <p>Bắt đầu hành trình.</p>
      </div>
    );
  }
}

export default About;
